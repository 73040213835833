import React from 'react';
import { AppStyles } from '../styles/AppStyles';
import styles from '../styles/Tailwind.css';

function Extraction(props) {
  const description = props.description;
  const body = props.body;

  return (
    <div class="flex flex-row py-2 px-3 justify-center items-center" style={AppStyles.extractionElement}>
      <div class="w-full">
        <div class="text-sm font-semibold">{description}</div>
        <span class="text-xs text-gray-500">{body}</span>
      </div>
    </div>
  );
}

export default Extraction;
