import React from 'react';
import { AppStyles } from '../styles/AppStyles';
import styles from '../styles/Tailwind.css';

function SideNavElement(props) {
  const date = props.date;
  const preview = props.preview;

  return (
    <div class="flex flex-row py-2 px-3 justify-center items-center border-b-2" style={AppStyles.sideNavElement}>
      <div class="w-full">
        <div class="text-sm font-semibold">{date}</div>
        <span class="text-xs text-gray-500">{preview}</span>
      </div>
    </div>
  );
}

export default SideNavElement;
