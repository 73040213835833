/* eslint-disable react-hooks/exhaustive-deps */
import { default as React, useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";

const EDITOR_HOLDER_ID = 'editorjs';

const Editor = (props) => {
  const ejInstance = useRef();
  const [extractedData, setExtractedData] = useState("");
  const editorData = (props.defaultNote);
  console.log("DEFAULT NOTE: ", editorData);

  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current = null;
    }
  }, []);

  const initEditor = () => {
    const EDITOR_JS_TOOLS = {
        embed: Embed,
        table: Table,
        marker: Marker,
        list: List,
        warning: Warning,
        code: Code,
        linkTool: LinkTool,
        image: Image,
        raw: Raw,
        header: Header,
        quote: Quote,
        checklist: CheckList,
        delimiter: Delimiter,
        inlineCode: InlineCode,
        simpleImage: SimpleImage
    };
    const editor = new EditorJS({
      holder: EDITOR_HOLDER_ID,
      logLevel: "ERROR",
      onReady: () => {
        ejInstance.current = editor;
        if(editorData.blocks[0])
          editor.render(editorData)
        console.log("EDITOR READY", editorData);
      },
      onChange: (api, event) => {
        console.log('Content changed!', event);
        editor.save().then((outputData) => {
            let plainText = "";
            for(let i = 0; i < outputData.blocks.length; i++){
                plainText += outputData.blocks[i].data.text;
                plainText += '\n';
            }
            props.formattedNote(outputData);
            props.plainText(plainText);
            
          }).catch((error) => {
            console.log('Saving failed: ', error)
          });
          
      },
      autofocus: true,
      tools: EDITOR_JS_TOOLS, 
    });
  };

  return (
    <React.Fragment>
        <div id={EDITOR_HOLDER_ID}> </div>
    </React.Fragment>
  );
}

export default Editor;