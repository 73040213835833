import React from 'react';
import {useState, useEffect, useRef} from 'react';
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Editor from './Editor';
import Extraction from './Extractions';
import SideNavElement from './SideNavElement';
import { AppStyles } from '../styles/AppStyles';
import { collection, getDocs, addDoc, updateDoc, where, query} from "firebase/firestore";
import {db} from '../firebase-config';
import styles from '../styles/Tailwind.css';
import axios from "axios";

const Home = () => {
  const allowList = ["qgMXKwHzjHckYQxgxfrtai9XEe22", "VPEnYnf86XVAjsS7bzoN9kPRBEk1", "L4yqoMmuUuh87VyXdbhaJuTxUz43"];
  const { logOut, user } = useUserAuth();
  const [filteredResponse, setFilteredResponse] = useState([]);
  const [lastChanged, setLastChanged] = useState(null);
  const [inputText, setInputText] = useState("");
  const [inputFormatted, setInputFormatted] = useState([]);
  const [defaultNote, setDefaultNote] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExtractionsLoading, setIsExtractionsLoading] = useState(false);
  const now = new Date();
  const prettyMonth = now.toLocaleString('default', { month: 'long'});
  const prettyDay = now.toLocaleString('default', { day: 'numeric'});
  const prettyYear = now.toLocaleString('default', { year: 'numeric'});
  const prettyDate = prettyMonth + ' ' + prettyDay + ', ' + prettyYear;
  const dateStamp = prettyDate;
  console.log(user.uid);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  if(!allowList.includes(user.uid)){
    handleLogout();
  }

  useEffect(() => {
    const fetchDefaultNote = async() => {
      const q = query(collection(db, "notes"), where("UID", "==", user.uid));
      await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("No matching documents.");
          setIsLoading(false);
          return;
        }
        const notes = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setDefaultNote(notes[0].content);
        setAllNotes(notes);
        console.log("FETCHED: ", notes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setIsLoading(false);
      });
    }
    fetchDefaultNote();
  }, [user]);

  const saveNote = async () => {
    const fetchDefaultNote = async() => {
      const q = query(collection(db, "notes"), where("UID", "==", user.uid), where("datestamp", "==", dateStamp));
      await getDocs(q)
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("CREATING NOTE")
          console.log(inputFormatted);
          try {
            const docRef = await addDoc(collection(db, "notes"), {
                UID: user.uid,
                content: inputFormatted,
                datestamp: dateStamp
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
          return;
        }
        console.log('Saving to existing note');
        const notesRef = collection(db, "notes");
        const q2 = await getDocs(query(notesRef, where("UID", "==", user.uid), where("datestamp", "==", dateStamp)));
        const updateDocument = async (docRef, data) => {
          try {
            await updateDoc(docRef, data);
            console.log('Document successfully updated:', docRef.id);
          } catch (error) {
            console.error('Error updating document:', docRef.id, error);
          }
        };
        q2.forEach((doc) => {
          const docRef = doc.ref;
          updateDocument(docRef, { content: inputFormatted });
        });
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
      });
    }
    fetchDefaultNote();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (new Date() - lastChanged > 2000) {
        console.log("SAVE");
        saveNote();
        if(inputText){
          
        }
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [lastChanged]);

  function runExtraction(){
    if(inputText){
      handleEntry(inputText);
    }
  }

  function entryUpdated(entry){
    setInputText(entry);
    setLastChanged(new Date());
  }
  async function handleEntry(plainText) {
    setIsExtractionsLoading(true);
    console.log(plainText);
    alert("Loading extractions, this could take a few moments");
    const prompt = plainText;
    axios
      .post("https://us-central1-relic-5bf7b.cloudfunctions.net/extractor", { prompt })
      .then((res) => {
        //let response = res.data;
        setFilteredResponse(res.data);
        setIsExtractionsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function Extractions(){
    const extractionArray = filteredResponse;
    let allExtractions = [];
    const summaryHead = "Summary";
    const summaryBody = extractionArray.summary;
    allExtractions.push({description: summaryHead, body: summaryBody});
    const remindersHead = "Reminders";
    let reminderString = "";
    if(extractionArray.reminders){
      for(let i = 0; i < extractionArray.reminders.length; i++){
        reminderString += '-' + extractionArray.reminders[i].text;
        reminderString += "\n";
      }
    }
    allExtractions.push({description: remindersHead, body: reminderString});
    const goalsHead = "Goals";
    let goalString = "";
    if(extractionArray.reminders){
      for(let i = 0; i < extractionArray.goals.length; i++){
        goalString += '-' + extractionArray.goals[i].text;
        goalString += "\n";
      }
    }
    allExtractions.push({description: goalsHead, body: goalString});
    const urlsHead = "URLs";
    let urlString = "";
    if(extractionArray.urls){
      for(let i = 0; i < extractionArray.urls.length; i++){
        urlString += '-' + extractionArray.urls[i].text;
        urlString += "\n";
      }
    }
    allExtractions.push({description: urlsHead, body: urlString});
    console.log(allExtractions);
    return (
      <ul>
        {allExtractions?.map((extraction) => (
          (extraction?.body ?
          <Extraction description={extraction?.description ? extraction.description : ""} body={extraction?.body ? extraction.body : ""} />
          : "")
        ))}
      </ul>
    );
  }

  function SideNav(){
    let allNavElements = [];
    allNotes.forEach((note) => {
      console.log("NOTE: ", note.datestamp);
      allNavElements.push({date: note.datestamp, preview: "Note preview text here soon..."});
    });
    return(
      <ul>
        {allNavElements?.map((element) => (
          <SideNavElement date={element?.date ? element.date : ""} preview={element?.preview ? element.preview : ""} />
        ))}
      </ul>
    );
  }

  if(isLoading){
    return (
      <p>Loading</p>
    );
  }else{
  return (
    <div class="mx-auto shadow-lg rounded-lg h-screen" style={AppStyles.container}>
    <div class="flex flex-row justify-between">
      <div class="flex flex-col w-2/5 border-r-2 overflow-y-auto h-screen" style={AppStyles.sideNavContainer}>
        <div class="flex flex-row py-2 px-3 justify-center items-center" style={AppStyles.sideNavHeader}>
          <div class="w-1/4">
            <button onClick={handleLogout}>
              <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                {
                  <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
                  fill="currentColor"
                />}
              </svg>
            </button>
          </div>
          <div class="w-full">
            <span class="text-sm text-black">My Journal</span> 
          </div>
          <div class="w-1/4">
            <svg className="h-6 w-6 text-gray-500" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 6.707a1 1 0 0 1 1.414 0L10 9.586l3.293-2.88a1 1 0 1 1 1.414 1.48l-4 3.5a1 1 0 0 1-1.164.12l-4-1.5a1 1 0 0 1-.54-.88V7.22a1 1 0 0 1 .54-.89z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <hr></hr>
        <SideNav/>
      </div>
      <div class="w-full px-5 flex flex-col justify-between" style={AppStyles.editorContainer}>
        <div class="flex flex-col mt-5">
          <Editor plainText={entryUpdated} formattedNote={setInputFormatted} defaultNote={defaultNote} />
        </div>
      </div>
      <div class="w-2/5 border-l-2" style={AppStyles.extractionContainer}>
        {isExtractionsLoading ? <p>Loading Extractions...</p> : ""}
        <Extractions/>
        <div class="flex flex-row py-2 px-3 justify-center items-center absolute bottom-10" style={AppStyles.tempSaveButton}>
          <div class="w-full">
            <button class="text-sm font-semibold text-center" onClick={runExtraction}>Relicify!</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
  }
};

export default Home;


