import React from "react";
import {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import Helmet from "react-helmet";

const Login = () => {
  const { googleSignIn } = useUserAuth();
  const navigate = useNavigate();
  const [showWaitlist, setShowWaitlist] = useState(false);
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      console.log(error.message);
    }
  };

 function Waitlist() {
  return (
      <>
      <button className="pr-0 mt-2 mr-2 text-gray-700 hover:text-gray-800" onClick={() => setShowWaitlist(false)}>
            <svg
              className="h-6 w-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div
              id="getWaitlistContainer"
              data-waitlist_id="5607"
          ></div>
          <Helmet>
              <link
                  rel="stylesheet"
                  type="text/css"
                  href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
              />
              <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
          </Helmet>
      </>
    );
  }

  const handleRequestAccess = (e) => {
    e.preventDefault();
    setShowWaitlist(true);
  };

  return (
    <div className="relative overflow-hidden">
    <div
      className="absolute"
      style={{
        width: "97px",
        height: "45px",
        left: "26px",
        top: "24px",
        fontFamily: "'Newsreader', sans-serif",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "2.7vw",
        color: "#232425",
      }}
    >
      Relic
    </div>
    <div className="absolute top-0 right-0 mr-4 mt-4">
      <button className="text-black px-6 py-3" style={{
        fontSize: "1.1vw",
      }}
      onClick={handleGoogleSignIn}>log In</button>
    </div>
    <div className="bg-gray-100 h-screen flex flex-col justify-center items-center">
      <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-7 mt-0" style={{
        fontFamily: 'Inter',
        fontStyle: "normal",
        lineHeight: "54px",
        color: "#222325",
      }}>Journaling just got smart.</h1>
      <p className="text-xl md:text-2xl text-gray-700 text-center">Magically turn daily notes into actions.</p>
      <p className="text-xl md:text-2xl text-gray-700 text-center mb-10">Input everything, do anything.</p>
      <div className="flex flex-col items-center pb-10">
        <div className="flex">
          <button className="bg-gray-600 text-white rounded-xl px-6 py-3 text-md md:text-xl font-bold hover:bg-gray-900 transition-colors duration-300 mb-4" onClick={handleRequestAccess} style={{
        fontFamily: 'Inter',
        fontStyle: "normal",
      }}>Request Access</button>
        </div>
      </div>
      {showWaitlist && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center"
          style={{
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="bg-white rounded-lg p-6">
            <Waitlist />
          </div>
        </div>
      )}
  
      <div className="absolute bottom-0 mb-0 w-full" style={{
          width: "80%",
          height: "40%",
          filter: "drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.25))",
          borderRadius: "8px",
        }}
        >
        <img src="hero.png" alt="Relic Notes" className="w-full h-auto" />
      </div>
    </div>
  </div>
  );
};

export default Login;