export const AppStyles = {
    container: {
      backgroundColor: "#EFF5F9",
      width: "100%",
      height: "100%"
    },
    sideNavContainer: {
        backgroundColor: "#FFFFFF",
    },
    sideNavHeader: {
        backgroundColor: "#FFFFFF",
        marginBottom: "10px",
        marginLeft: "8px",
        marginRight: "8px"
    },
    sideNavElement: {
        backgroundColor: "#F5F5F5",
        borderRadius: "10px",
        marginTop: "10px",
        marginLeft: "8px",
        marginRight: "8px"
    },
    extractionElement: {
        backgroundColor: "#F5F5F5",
        borderRadius: "10px",
        marginTop: "10px",
        marginLeft: "8px",
        marginRight: "8px"
    },
    editorContainer: {
        backgroundColor: "#FFFFFF",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        marginTop: "20px",
        marginLeft: "20px",
        marginBottom: "20px",
    },
    extractionContainer: {
        backgroundColor: "#FFFFFF",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        marginTop: "20px",
        marginRight: "20px",
        marginBottom: "20px",
    },
    tempSaveButton: {
        backgroundColor: "#545454",
        color: "#FFFFFF",
        borderRadius: "10px",
        marginTop: "10px",
        marginLeft: "8px",
        marginRight: "8px",
    },
    
  };